import React, { forwardRef, useCallback, useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha"; // v2
import { useReCaptchaContext } from '../../contexts/ReCaptchaContext';

import "../../styles/forms.css";
import "../../styles/loader.css";
import "../../styles/animation.css";

const Form = forwardRef((props, ref) => {
  const {
    URL,
    className,
    method,
    action,
    enctype,
    title,
    instructions,
    inputs,
    submitButtonText,
    onSubmitCallback,
    clearButtonText,
    onClearCallback,
    onSuccessComponent
  } = props;

  const methods = useForm();
  const { reCaptchaV2Key, reCaptchaSuccess, setReCaptchaSuccess, verifyReCaptcha } = useReCaptchaContext();
  const isRecaptchaV2Visible = useMemo(() => !reCaptchaSuccess, []); // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const formStateName = `formstate.${className}`;
  const formState = methods.watch();

  // Store the form state in session storage.
  useEffect(() => {
    if (Object.getOwnPropertyNames(formState).length) {
      sessionStorage.setItem(formStateName, JSON.stringify(formState));
    }
  }, [formState, formStateName]);

  // Retrieve the form state from session storage.
  useEffect(() => {
    const formData = sessionStorage.getItem(formStateName);
    if (formData) {
      methods.reset(JSON.parse(formData));
    }
  }, [methods, formStateName]);

  const submitHandler = useCallback(async (data) => {
    setLoading(true);
    setError("");
    
    if (!reCaptchaSuccess) {
      setError("Please verify your humanity, then try submitting again!");
      setLoading(false);
      return;
    }

    const err = await onSubmitCallback(data);
    if (err !== null) {
      setError(err);
      setLoading(false);
      return;
    }

    sessionStorage.removeItem(formStateName);
    setSuccess(true);
    setLoading(false);
  }, [setLoading, setError, reCaptchaSuccess, onSubmitCallback, formStateName, setSuccess]);

  const clearHandler = () => {
    sessionStorage.removeItem(formStateName);
    onClearCallback();
  }

  if (loading) {
    return (
      <div className="loader-box">
        <span className="loader"></span>
      </div>
    );
  }

  if (success) {
    return (
      <div className="form-success-box">
        {onSuccessComponent}
      </div>
    );
  }

  return (
    <div className={`form-box ${className}-box`}>
      <h1 className='form-title'>{title}</h1>
      <p className='form-instructions'>{instructions}</p>
      <FormProvider {...methods}>
        <form ref={ref} className={`form ${className}`}
          action={action} method={method} encType={enctype}
          onSubmit={methods.handleSubmit(submitHandler)}
        >
          {inputs.map((input) => {
            return (<div key={input.key}>
              {input}
            </div>);
          })}
          <div className={`recaptcha-box ${isRecaptchaV2Visible ? "" : "hidden"}`}>
            <p>Please verify that you are not a robot:</p>
            <ReCAPTCHA
              sitekey={reCaptchaV2Key}
              theme="dark"
              onChange={async (token) => {
                try {
                  const captchaResV2 = await verifyReCaptcha(URL, "v2", token);
                  setReCaptchaSuccess(captchaResV2.success);
                } catch (error) {
                  console.trace(error);
                }
              }}
            />
          </div>
          <div className='button-box'>
            <button className='button-56 submit' type='submit'>
              {submitButtonText}
            </button>
            <button className='button-56 close' type='reset'
              onClick={clearHandler}
            >
              {clearButtonText}
            </button>
          </div>
          <div className='submit-error-box'>
            <span className='error-message'>{error}</span>
          </div>
          <div className="grecaptcha-disclaimer">
            <small>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>
          </div>
        </form>
      </FormProvider>
    </div>
  );
});

export default Form