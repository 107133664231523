import { createContext, useContext, useMemo, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import axios from "axios";

// Create the context
const ReCaptchaContext = createContext();

// Create a custom hook to use the MyContext
const useReCaptchaContext = () => {
  const context = useContext(ReCaptchaContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};

const ReCaptchaProvider = ({ children }) => {
  const [reCaptchaSuccess, setReCaptchaSuccess] = useState(false);
  const reCaptchaV3Key = useMemo(() => {
    return "6Ld88PIpAAAAAPevZT8jFDgYpNQvX4aFaSpbGMmr";
  }, []);
  const reCaptchaV2Key = useMemo(() => {
    return "6LcMRvQpAAAAAABXH837bvbZcz5KM9TYQqtdykOo";
  }, []);

  const verifyReCaptcha = async (baseURL, version, token) => {
    try {
      const res = await axios.post(`${baseURL}/api/verify-captcha?version=${version}&token=${encodeURIComponent(token)}`);
      return res.data;
    } catch (error) {
      console.error(`Error validating recaptcha ${version}`);
      throw error;
    }
  }
  
  const value = {
    reCaptchaV3Key,
    reCaptchaV2Key,
    reCaptchaSuccess,
    setReCaptchaSuccess,
    verifyReCaptcha
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptchaV3Key}
    >
      <ReCaptchaContext.Provider value={value}>
        {children}
      </ReCaptchaContext.Provider>
    </GoogleReCaptchaProvider>
  );
};

export { 
  ReCaptchaContext, 
  useReCaptchaContext, 
  ReCaptchaProvider 
};