import React, { useState, useRef, useEffect, useMemo } from 'react';
import Hero from './Hero';
import Menu from './Menu';
import BookCustomForm from './forms/BookCustom';
import NewsletterForm from './forms/Newsletter';
import InteractiveCarousel from './InteractiveCarousel';
import fetchJsonData from '../scripts/fetchJsonData';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useReCaptchaContext } from '../contexts/ReCaptchaContext';

import '../styles/main.css';
import '../styles/animation.css';

/**
 * Root of the Application.
 */
function App() {
  const heroRef = useRef(null);
  const menuRef = useRef(null);
  const contentRef = useRef(null);
  const [instaData, setInstaData] = useState([]);
  const [content, setContent] = useState(<></>);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { verifyReCaptcha, setReCaptchaSuccess } = useReCaptchaContext();

  const maxCarouselItems = useMemo(() => 3, []);
  const URL = useMemo(() => {
    return ['https://web-api-server-2n4ttkjqkq-uc.a.run.app', 'http://localhost:8008']
  }, [])[0];

  // Get captcha v3 score.
  useEffect(() => {
    (async () => {
      if (!executeRecaptcha || !verifyReCaptcha) {
        return;
      }

      let captchaResV3;
      try {
        const token = await executeRecaptcha("mainpage");
        captchaResV3 = await verifyReCaptcha(URL, "v3", token);
        if (captchaResV3.success && captchaResV3.score >= 0.5) {
          setReCaptchaSuccess(true);
        } else {
          setReCaptchaSuccess(false);
        }
      } catch (error) {
        console.trace(error);
      }
    })();
  }, [URL, executeRecaptcha]); // eslint-disable-line

  // Retrieve creds and instagram posts from API.
  useEffect(() => {
    async function initialize() {
      fetchJsonData(
        `${URL}/api/posts`,
        { credentials: "include" },
        (data) => {
          setInstaData(data);
          setContent(<InteractiveCarousel data={data} maxVisibleItems={maxCarouselItems} />);
        });
    }

    initialize();
  }, [maxCarouselItems, URL]);

  function changeLayout(content) {
    heroRef.current.classList.remove('intro');
    menuRef.current.classList.remove('intro');
    transitionContent(content);
  }

  function revertLayout() {
    heroRef.current.classList.add('intro');
    menuRef.current.classList.add('intro');
    transitionContent(<InteractiveCarousel data={instaData} maxVisibleItems={maxCarouselItems} />);
  }

  function transitionContent(content) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    contentRef.current.classList.add('noopac');
    setTimeout(() => {
      setContent(content);
      contentRef.current.classList.remove('noopac');
    }, 250);
  }

  function toggleView(itemName, content) {
    isVisible(itemName) ? revertLayout() :
      changeLayout(content);
  }

  function isVisible(itemName) {
    return content.type.name === itemName;
  }

  const menuOptions = [
    {
      buttonContent: "NEWSLETTER",
      onClickCallback: () => toggleView("NewsletterForm", <NewsletterForm URL={URL} revertLayout={revertLayout} />),
    },
    {
      buttonContent: "BOOK CUSTOM",
      onClickCallback: () => toggleView("BookCustomForm", <BookCustomForm URL={URL} revertLayout={revertLayout} />),
    },
    {
      buttonContent: "BOOK FLASH",
      onClickCallback: () => window.open('https://app.bookme.ink/kamagi', '_blank'),
      externalLink: true
    },
  ]

  return (
    <>
      <Hero ref={heroRef} />
      <Menu ref={menuRef} menuOptions={menuOptions} />
      <div ref={contentRef} className='content-box'>
        {content}
      </div>
    </>
  );
}

export default App;
