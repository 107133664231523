/**
 * @typedef {Object} SubmitResult
 * @property {Number} code - The HTTP code.
 * @property {String} userMessage - The message to display to the user.
 * @property {String} systemMessage - The more specific system-related message.
 */
function SubmitResult(code, userMessage, systemMessage) {
  return {
    code,
    userMessage,
    systemMessage
  };
}

const friendlyErrorMessage = "An error occurred while processing your information. Wait a moment then try again. If it still does not work, send me an email!";


/**
 * Submit to the newsletter endpoint of the server
 * 
 * @param {String} URL The base URL to which the request will be made
 * @param {Object} data An object with the fields { firstname, lastname, email }
 */
async function submitNewlsetter(URL, data) {
  const method = 'POST';
  const action = '/newsletter/subscribe';
  const headers = {
    "content-type": 'application/x-www-form-urlencoded'
  }
  const body = Object.entries(data).map(([key, value]) =>
    encodeURIComponent(key) + '=' + encodeURIComponent(value)
  ).join('&');

  const options = {
    method: method,
    credentials: "include",
    headers: headers,
    body: body
  };

  try {
    const res = await fetch(`${URL}${action}`, options);
    const body = await res.text();
    if (!res.ok) {
      if (res.status === 409) {
        return SubmitResult(res.status, "The email submitted is already subscribed!", body);
      }
      throw new Error(SubmitResult(res.status, friendlyErrorMessage, body));
    }
    console.debug(`Response from ${method} ${URL}${action}: (${res.status}) ${res.statusText}\nBody:`, body);
    return null;
  } catch (error) {
    console.debug(`Error in ${method} ${URL}${action}:`, error);
    return error; // SubmitResult
  }
}

/**
 * Submit to the custom booking endpoint of the server
 * 
 * @param {String} URL The base URL to which the request will be made
 * @param {FormData} data The form fields encoded into a FormData object
 */
async function submitCustomBooking(URL, data) {
  const method = 'POST';
  const action = '/book/custom';
  // Content-Type set correctly by browser.

  const options = {
    method: method,
    credentials: "include",
    body: data,
  };

  try {
    const res = await fetch(`${URL}${action}`, options);
    const body = await res.text();
    if (!res.ok) {
      throw new Error(SubmitResult(res.status, friendlyErrorMessage, body));
    }
    console.debug(`Response from ${method} ${URL}${action}: (${res.status}) ${res.statusText}\nBody:`, body);
    return null;
  } catch (error) {
    console.debug(`Error in ${method} ${URL}${action}:`, error);
    return error; // SubmitResult
  }
}

export {
  submitNewlsetter,
  submitCustomBooking
}