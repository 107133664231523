import React, { useRef } from 'react'
import { Input, Validator } from './Inputs';
import Form from './Form';
import { submitCustomBooking, submitNewlsetter } from './SubmitFunctions';

/**
 * Custom booking form.
 * @param {Function} revertLayout function that reverts the application layout to its original state.
 */
export default function BookCustomForm({ URL, revertLayout }) {
  const formRef = useRef(null);
  const className = 'book-custom';
  const title = 'BOOK CUSTOM';
  const instructions = "Fill out and submit the form below to book a custom piece"
  const acceptedMimeTypesMap = {
    ".jpeg, .jpg": "image/jpeg",
    ".png": "image/png",
    ".webp": "image/webp",
    ".svg": "image/svg+xml",
    ".heic": "image/heic",
  };
  const maxLengthText = 100;
  const maxLengthTextarea = 1000;
  const maxFilesSizeMB = 10;
  const inputs = [
    <Input.Text.Wrapped key={'firstname'} name={'firstname'} type={'firstname'} labelText={'First Name'}
      required={true} maxLength={maxLengthText} />,

    <Input.Text.Wrapped key={'lastname'} name={'lastname'} type={'lastname'} labelText={'Last Name'}
      required={true} maxLength={maxLengthText} />,

    <Input.Text.Wrapped key={'pronouns'} name={'pronouns'} type={'pronouns'} labelText={'Pronouns'}
      required={true} maxLength={maxLengthText} />,

    <Input.Text.Wrapped key={'phone-insta'} name={'phone-insta'} type={'text'} labelText={'Phone Number or Instagram'}
      required={true} maxLength={maxLengthText} />,

    <Input.Text.Wrapped key={'email'} name={'email'} type={'text'} labelText={'Email'}
      required={true} maxLength={maxLengthText} validation={{ email: Validator.Text.validEmail }} />,

    <Input.Textarea.Wrapped key={'description'} name={'description'} type={'textarea'} labelText={'Design Description'}
      required={true} maxLength={maxLengthTextarea}
      postamble={'Please go into depth, especially if you have specifics in mind (ex. portrait facing a certain direction, hair style, types of flowers, etc.)'} />,

    <Input.Text.Wrapped key={'size'} name={'size'} type={'text'} labelText={'Size'}
      required={true} maxLength={maxLengthText}
      postamble={'In inches'} />,

    <Input.Text.Wrapped key={'placement'} name={'placement'} type={'text'} labelText={'Placement'}
      required={true} maxLength={maxLengthText}
      postamble={'Please include which side of the body'} />,

    <Input.File.Wrapped key={'picturereference'} name={'picturereference'} type={'file'} labelText={'Reference Pictures'}
      accept={Object.values(acceptedMimeTypesMap).join(",")} multiple={true}
      required={true} validation={{ isSize: Validator.File.maxSizeMB(maxFilesSizeMB * 2), isType: Validator.File.isMimeType("Files must be an accepted image format.", Object.values(acceptedMimeTypesMap)) }}
      postamble={<>
        <div>Please attach images of the area you would like tattooed</div><br />
        <div>Supported file types: {Object.keys(acceptedMimeTypesMap).join(", ")}</div>
      </>}
    />,

    <Input.CheckboxArray.Wrapped key={'availability'} name={'availability-wrapper'} labelText={'Availability'}
      checkboxes={[
        <Input.Checkbox.Plain key={"availability-0"} name={'availability-0'} labelText={'Wednesday'} />,
        <Input.Checkbox.Plain key={"availability-1"} name={'availability-1'} labelText={'Thursday'} />,
        <Input.Checkbox.Plain key={"availability-2"} name={'availability-2'} labelText={'Friday'} />,
        <Input.Checkbox.Plain key={"availability-3"} name={'availability-3'} labelText={'Saturday'} />
      ]}
      validation={Validator.CheckboxArray.oneMustBeChecked} />,

    <Input.Text.Wrapped key={'budget'} name={'budget'} type={'text'} labelText={'Budget'} postamble={'Optional'}
      maxLength={maxLengthText} />,

    <Input.Textarea.Wrapped key={'additionalinfo'} name={'additionalinfo'} type={'textarea'} labelText={'Additional information'} postamble={'Optional'}
      maxLength={maxLengthTextarea} />,

    <Input.Checkbox.Wrapped key={'termsconditions'} name={'termsconditions'} type={'checkbox'} labelText={'All of the information I have provided is accurate and I have read and agree to the terms and conditions.'}
      preamble={<TermsAndConditions />}
      validation={Validator.Checkbox.mustBeChecked} />,

    <Input.Checkbox.Plain key={'newsletter-optin'} name={'newsletter-optin'} type={'checkbox'} labelText={"Opt in for the newsletter to stay updated about new flash, guest spots, merch, and more!"}
    />,
  ];
  const submitText = 'SUBMIT';
  const onSubmitCallback = async (data) => {
    const requests = [];

    requests.push(submitCustomBooking(URL, new FormData(formRef.current)));

    if (data['newsletter-optin']) {
      requests.push(submitNewlsetter(URL, {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email
      }));
    }

    const submitResults = await Promise.all(requests);
    const trueError = submitResults.find(result => result?.code !== 409); // Filter for a non-409 error
    if (trueError) {
      return trueError.userMessage;
    }

    return null;
  }
  const clearButtonText = 'CANCEL';
  const onClearCallback = () => {
    revertLayout();
  }

  function TermsAndConditions() {
    return (
      <div className='tc-box'>
        <p>Please read and understand the following terms and conditions:</p>
        <ul>
          <li>I confirm that I am over 18 years of age.</li>
          <li>I understand that a tattoo is a permanent change to my body.</li>
          <li>I understand that a $150 deposit is required to book an appointment, and that in case of a reschedule or cancellation, I must provide AT LEAST 72 hours' notice before the date of the appointment, otherwise the deposit will be forfeit.</li>
          <li>I understand that the design will not be shown until the day of the appointment.</li>
          <li>I have provided the necessary design information and specifics to my artist, and understand that a major change in design on the day of may lead to a reschedule and would require another deposit.</li>
          <li>I consent to having pictures taken of my tattoo to be used for social media/ promotional posts.</li>
          <li>I understand that I am responsible for the aftercare of a tattoo and that negligence of healing will affect the possibility of a complimentary touch up within a year.</li>
          <li>I understand that in case I am over 20 minutes late to my appointment, it will be canceled and a rescheduled appointment will require another deposit.</li>
          <li>I have informed my artist of any health conditions that may affect the tattoo or tattoo session.</li>
          <li>I confirm my tattoo artist is not liable for any claims, damages, and/or expenses.</li>
        </ul>
      </div>
    );
  }

  return (
    <Form
      ref={formRef}
      URL={URL}
      className={className}
      title={title}
      instructions={instructions}
      inputs={inputs}
      submitButtonText={submitText}
      onSubmitCallback={onSubmitCallback}
      clearButtonText={clearButtonText}
      onClearCallback={onClearCallback}
      onSuccessComponent={<BookCustomFormSuccess />}
    />
  );
}

function BookCustomFormSuccess() {
  return (
    <>
      <p>
        Booking made successfully.
      </p>
      <p>
        I will be in touch with you soon!
      </p>
    </>
  )
}