import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { ReCaptchaProvider } from "./contexts/ReCaptchaContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ReCaptchaProvider>
      <App />
    </ReCaptchaProvider>
  // </React.StrictMode>
);
