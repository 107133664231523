import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import SVGLibrary from '../scripts/svgLibrary';

import '../styles/interactive-carousel.css';

export default function InteractiveCarousel({ data, maxVisibleItems }) {

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(maxVisibleItems - 1);
  const carouselView = useRef(null);
  const leftButton = useRef(null);
  const rightButton = useRef(null);

  // Set the maximum number of items visible in the CSS properties to enable proper styling.
  useEffect(() => {
    document.documentElement.style.setProperty('--max-posts', maxVisibleItems);
  }, [maxVisibleItems]);

  // Determine what is currently visible inside the carouselView and set startIndex and endIndex accordingly.
  const carouselViewScrollListener = useCallback(() => {
    let _si = 0;
    let _ei = data.length - 1;
    while (!isWithinView(carouselView.current.querySelector(`.ib${_si}`), carouselView.current)) {
      ++_si;
    }
    while (!isWithinView(carouselView.current.querySelector(`.ib${_ei}`), carouselView.current)) {
      --_ei;
    }
    setStartIndex(_si);
    setEndIndex(_ei);
  }, [data.length, carouselView])

  // Support for browsers that do not have the 'onscrollend' event (Safari).
  const scrollEndExists = useMemo(() => {
    return 'onscrollend' in document;
  }, []);

  // Add the scroll end listener to the carouselView.
  useEffect(() => {
    const carouselViewDOM = carouselView.current;

    // Support for browsers that do not have the 'onscrollend' event (Safari).
    if (!scrollEndExists) {
      carouselViewDOM.addEventListener('scroll', pollEventEnd(carouselViewScrollListener, 150));
      return () => {
        carouselViewDOM.removeEventListener('scroll', pollEventEnd(carouselViewScrollListener, 150));
      }
    }

    carouselViewDOM.addEventListener('scrollend', carouselViewScrollListener);
    return () => {
      carouselViewDOM.removeEventListener('scrollend', carouselViewScrollListener);
    }
  }, [carouselView, scrollEndExists, carouselViewScrollListener])

  // Control the visibility and usability of the scroll buttons.
  const [goLeft, goRight] = useMemo(() => {
    const l = (startIndex > 0);
    const r = (endIndex < data.length - 1);
    return [l, r];
  }, [startIndex, endIndex, data.length])

  // Scroll left into view.
  function handleLeftClick() {
    if (!goLeft) return;

    const leftScrollTarget = carouselView.current.querySelector(`.ib${startIndex - 1}`);
    leftScrollTarget.scrollIntoView({ behavior: "auto" });
  }

  // Scroll right into view.
  function handleRightClick() {
    if (!goRight) return;

    const rightScrollTarget = carouselView.current.querySelector(`.ib${endIndex + 1}`);
    rightScrollTarget.scrollIntoView({ behavior: "auto" });
  }

  // Change action on left button when goLeft changes.
  useEffect(() => {
    if (!goLeft) {
      leftButton.current.classList.add('disable');
    } else {
      leftButton.current.classList.remove('disable');
    }
  }, [leftButton, goLeft]);

  // Change action on right button when goRight changes.
  useEffect(() => {
    if (!goRight) {
      rightButton.current.classList.add('disable');
    } else {
      rightButton.current.classList.remove('disable');
    }
  }, [rightButton, goRight]);

  return (
    <div className='carousel-box'>
      <button ref={leftButton} className='left-button' onClick={handleLeftClick}>
        <SVGLibrary.Chevron />
      </button>
      <div ref={carouselView} className='carousel-content-box' >
        <div className='carousel-content'>
          {data && data.map((item, index) => {
            return (
              <Item key={item.id} itemInfo={item} index={index} />
            )
          })}
        </div>
      </div>
      <button ref={rightButton} className='right-button' onClick={handleRightClick}>
        <SVGLibrary.Chevron />
      </button>
    </div>
  )
}

function Item({ itemInfo, index }) {
  const [imageLoaded, setImageLoaded] = useState(true);

  function handleImageLoadError() {
    setImageLoaded(false);
  }

  const url = useMemo(() => itemInfo.media_type === "VIDEO"
    ? itemInfo.thumbnail_url
    : itemInfo.media_url,
    [itemInfo]);

  return (
    <div className={`item-box ib${index}`}>
      <a target='_blank' rel='noreferrer' href={itemInfo.permalink}>
        {imageLoaded ?
          <img src={url} alt={itemInfo.caption} onError={handleImageLoadError} />
          :
          <img src='/instagram.svg' alt={itemInfo.caption} />
        }
      </a>
    </div>
  );
}

function isWithinView(element, viewElement) {
  const elementRect = element.getBoundingClientRect();
  const viewRect = viewElement.getBoundingClientRect();

  // Check if element is within the viewElement
  const isWithinHorizontal = elementRect.right >= viewRect.left && elementRect.left <= viewRect.right;
  const isWithinVertical = elementRect.bottom >= viewRect.top && elementRect.top <= viewRect.bottom;

  // Return true if within both horizontal and vertical bounds
  return isWithinHorizontal && isWithinVertical;
}

function pollEventEnd(callback, limit) {
  let isScrolling;
  return () => {
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      callback();
    }, limit);
  }
}