import React, { forwardRef } from 'react';
import SVGLibrary from '../scripts/svgLibrary';
import '../styles/menu.css';

const Menu = forwardRef(function Menu(props, ref) {
  const menuOptions = props.menuOptions;

  return (
    <section className='menu intro' ref={ref}>
      <ul>
        {menuOptions.map(option => {
          return (
            <li key={option.buttonContent} onClick={option.onClickCallback}>
              <button className="button-56">
                {option.buttonContent}
                {option.externalLink === true ? <SVGLibrary.ExternalLink /> : <></>}
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  )
});

export default Menu;