import React, { useRef } from 'react'
import { Input, Validator } from './Inputs';
import Form from './Form';
import { submitNewlsetter } from './SubmitFunctions';

/**
 * Newsletter signup form.
 * @param {Function} revertLayout function that reverts the application layout to its original state.
 */
export default function NewsletterForm({ URL, revertLayout }) {
  const formRef = useRef(null);
  const className = 'newsletter';
  const title = "NEWSLETTER SIGNUP";
  const instructions = "My newsletter contains information about where I'll be guest spotting, new flash or merch, giveaways, and deals"
  const inputs = [
    <Input.Text.Wrapped key={'firstname'} name={'firstname'} type={'name'} labelText={'First Name'}
      required={true} maxLength={100} />,

    <Input.Text.Wrapped key={'lastname'} name={'lastname'} type={'name'} labelText={'Last Name'}
      required={true} maxLength={100} />,

    <Input.Text.Wrapped key={'email'} name={'email'} type={'email'} labelText={'Email'}
      required={true} maxLength={100} validation={{ email: Validator.Text.validEmail }} />
  ];
  const submitText = 'SUBSCRIBE';
  const onSubmitCallback = async (data) => {
    const submitResult = await submitNewlsetter(URL, data);
    if (submitResult) {
      return submitResult.userMessage;
    }
    return null;
  }

  const clearButtonText = 'CANCEL';
  const onClearCallback = () => {
    revertLayout();
  }

  return (
    <Form
      ref={formRef}
      URL={URL}
      className={className}
      title={title}
      instructions={instructions}
      inputs={inputs}
      submitButtonText={submitText}
      onSubmitCallback={onSubmitCallback}
      clearButtonText={clearButtonText}
      onClearCallback={onClearCallback}
      onSuccessComponent={<NewsletterFormSuccess />}
    />
  );
}

function NewsletterFormSuccess() {
  return (
    <p>
      Success! Thanks for subscribing to my newsletter!
    </p>
  );
}