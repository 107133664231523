import React, { useRef, useState, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import EmailValidator from "email-validator";
import SVGLibrary from "../../scripts/svgLibrary";
import { compress, compressHeic } from "../../util/compress.js";

/**
 * Component library of input types. Each input type can be created plain or wrapped.
 */
export const Input = {
  Text: {
    Plain: ({ name, type, labelText, maxLength, required, validation }) => {
      const { register, formState: { errors, defaultValues } } = useFormContext();
      const [length, setLength] = useState(0);

      useEffect(() => {
        if (!!defaultValues && !!defaultValues[name]) {
          setLength(defaultValues[name].length);
        }
      }, [setLength, defaultValues, name]);

      if (maxLength) {
        if (!validation) {
          validation = {};
        }
        validation.maxLength = Validator.Text.maxLength(maxLength)
      }

      const options = {
        required: required ? 'This field is required' : required,
        validate: validation,
        onChange: (e) => setLength(e.target.value.length),
      }

      return (
        <>
          <label className={`label ${name}`}>
            <span className='label-text'>{labelText}</span>
            <input className={`input ${name}`} type={type} name={name} placeholder='.'
              {...register(name, options)}></input>
            {!!maxLength && <span className={`character-count-text ${(!length || length < 0.8 * maxLength) ? 'noopac' : ''}`}>{length} / {maxLength}</span>}
          </label>
          <ErrorMessage errors={errors} name={name}
            render={({ message }) => {
              console.debug('Error:', name, errors[name]);
              return (
                <div className='error-box'>
                  <span className='error-message'>{message}</span>
                </div>
              );
            }}
          />
        </>
      );
    },
    Wrapped: ({ name, type, labelText, maxLength, required, validation, preamble, postamble }) => {
      return <InputWrapper
        input={<Input.Text.Plain name={name} type={type} labelText={labelText} maxLength={maxLength} required={required} validation={validation} />}
        preamble={preamble} postamble={postamble} />;
    }
  },
  Textarea: {
    Plain: ({ name, labelText, maxLength, required, validation }) => {
      const { register, formState: { errors, defaultValues } } = useFormContext();
      const [length, setLength] = useState(0);

      useEffect(() => {
        if (!!defaultValues && !!defaultValues[name]) {
          setLength(defaultValues[name].length);
        }
      }, [setLength, defaultValues, name]);

      if (maxLength) {
        if (!validation) {
          validation = {};
        }
        validation.maxLength = Validator.Text.maxLength(maxLength)
      }

      const options = {
        required: required ? 'This field is required' : required,
        validate: validation,
        onChange: (e) => setLength(e.target.value.length),
      }

      return (
        <>
          <label className={`label ${name}`}>
            <span className='label-text'>{labelText}</span>
            <textarea className={`input ${name}`} type='text' name={name} placeholder='.'
              {...register(name, options)}></textarea>
            {!!maxLength && <span className={`character-count-text ${(!length || length < 0.8 * maxLength) ? 'noopac' : ''}`}>{length} / {maxLength}</span>}
          </label>
          <ErrorMessage errors={errors} name={name}
            render={({ message }) => {
              console.debug('Error:', name, errors[name]);
              return (
                <div className='error-box'>
                  <span className='error-message'>{message}</span>
                </div>
              );
            }}
          />
        </>
      );
    },
    Wrapped: ({ name, labelText, maxLength, required, validation, preamble, postamble }) => {
      return <InputWrapper
        input={<Input.Textarea.Plain name={name} labelText={labelText} maxLength={maxLength} required={required} validation={validation} />}
        preamble={preamble} postamble={postamble} />;
    }
  },
  Checkbox: {
    Plain: ({ name, labelText, required, validation }) => {
      const { register, formState: { errors } } = useFormContext();
      const options = {
        required: required ? 'This field is required' : required,
        validate: validation
      };
      const { ref, ...inputRegistration } = register(name, options);
      const inputRef = useRef(null);

      return (
        <>
          <div className='checkbox-box' >
            <input className={`checkbox-input ${name}`} type='checkbox' name={name}
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
              {...inputRegistration}></input>
            <label className={`checkbox-label ${name}`} htmlFor={name}>
              <span className='checkbox-label-text'>{labelText}</span>
            </label>
          </div>
          <ErrorMessage errors={errors} name={name}
            render={({ message }) => {
              console.debug('Error:', name, errors[name]);
              return (
                <div className='error-box'>
                  <span className='error-message'>{message}</span>
                </div>
              );
            }}
          />
        </>
      );
    },
    Wrapped: ({ name, labelText, required, validation, preamble, postamble }) => {
      return <InputWrapper
        input={<Input.Checkbox.Plain name={name} labelText={labelText} required={required} validation={validation} />}
        preamble={preamble} postamble={postamble} />;
    }
  },
  CheckboxArray: {
    Plain: ({ name, labelText, checkboxes, validation }) => {
      const { register, formState: { errors } } = useFormContext();

      const inputs = useMemo(() => {
        const inputs = checkboxes
          .map(checkbox => checkbox.props.name)
          .map(name => document.getElementsByName(name)); // TODO: this needs to be ref instead of accessing dom
        return inputs;
      }, [checkboxes]);

      const options = {
        validate: validation(inputs.map(element => element[0]))
      };

      return (
        <>
          <div className="checkbox-array-label">{labelText}</div>
          <div className={`checkbox-array-box ${name}`}
            {...register(name, options)}
          >
            {checkboxes}
          </div>
          <ErrorMessage errors={errors} name={name}
            render={({ message }) => {
              console.debug('Error:', name, errors[name]);
              return (
                <div className='error-box'>
                  <span className='error-message'>{message}</span>
                </div>
              );
            }}
          />
        </>
      )
    },
    Wrapped: ({ name, labelText, checkboxes, validation, preamble, postamble }) => {
      return <InputWrapper
        input={<Input.CheckboxArray.Plain name={name} labelText={labelText} checkboxes={checkboxes} validation={validation} />}
        preamble={preamble} postamble={postamble} />;
    }
  },
  File: {
    Plain: ({ name, labelText, accept, multiple, required, validation }) => {
      const inputRef = useRef(null);
      const [uploadPreview, setUploadPreview] = useState(null);
      const [files, setFiles] = useState(null);
      const [isUpdating, setIsUpdating] = useState(false);
      const [updatingMessage, setUpdatingMessage] = useState("");

      const { register, formState: { errors } } = useFormContext();
      const options = {
        required: required ? 'This field is required' : required,
        validate: validation,
        onChange: () => handleFilesAdded()
      }
      const { ref, ...inputRegistration } = register(name, options);

      function UploadPrompt() {
        return (
          <div className='file-prompt'>
            {
              isUpdating ?
                <>
                  <SVGLibrary.PendingDots />
                  <p>{updatingMessage}</p>
                </>
                :
                <>
                  <SVGLibrary.UploadCloud />
                  <p>Click Here to Upload Files</p>
                </>
            }
          </div>
        );
      }

      function UploadDisplay({ files }) {
        function returnFileSize(number) {
          if (number < 1024) {
            return `${number} bytes`;
          } else if (number >= 1024 && number < 1048576) {
            return `${(number / 1024).toFixed(1)} KB`;
          } else if (number >= 1048576) {
            return `${(number / 1048576).toFixed(1)} MB`;
          }
        }

        return (
          <div className='file-display-box'>
            {files && Array.from(files).map((file) => {
              const _url = URL.createObjectURL(file);
              return (
                <div key={file.name} className={`file-display ${file.name}`} tabIndex={0}
                  onClick={() => removeUpload(file)}
                >
                  <div className='file-display-image-box'>
                    <img src={_url} alt={file.name} title={file.name} />
                  </div>
                  <p>{returnFileSize(file.size)}</p>
                  <SVGLibrary.XButton />
                </div>
              );
            })}
          </div>
        )
      }

      async function handleFilesAdded() {
        setUpdatingMessage("Updating...");
        setIsUpdating(true);
        const newFiles = Array.from(inputRef.current.files);
        const compressionPromises = newFiles
          .reduce((acc, file) => {
            try {
              let compressionPromise;
              if (file.type === "image/heic") {
                compressionPromise = compressHeic(file);
              } else {
                compressionPromise = compress(file);
              }
              acc.push(compressionPromise);
            } catch (error) {
              console.error("Could not compress file:", error);
            }
            return acc;
          }, []);
        setUpdatingMessage("Compressing...");
        const compressedFiles = await Promise.all(compressionPromises);
        addUpload(compressedFiles);
        setIsUpdating(false);
        setUpdatingMessage("");
      }

      // Add new files to the Upload Display and to the file input's FileList.
      function addUpload(newFiles) {
        const fileArrCurr = (files) ? Array.from(files) : [];
        const fileArr = [...fileArrCurr, ...newFiles];
        const dataTransfer = new DataTransfer();
        fileArr.forEach(file => {
          try {
            dataTransfer.items.add(file);
          } catch (error) {
            console.error(`Could not add item '${file.name}' to file list:`, error);
          }
        });
        setFiles(dataTransfer.files);
        inputRef.current.files = dataTransfer.files;
      }

      // Remove a file from the Upload Display and from the file input's FileList.
      function removeUpload(fileInfo) {
        const fileArray = Array.from(inputRef.current.files);
        const indexToRemove = fileArray.indexOf(fileInfo);
        if (indexToRemove !== -1) {
          fileArray.splice(indexToRemove, 1);
        }
        const dataTransfer = new DataTransfer();
        fileArray.forEach(file => dataTransfer.items.add(file));
        setFiles(dataTransfer.files);
        inputRef.current.files = dataTransfer.files;
      }

      // Re-render the Upload Display when the files are changed.
      useEffect(() => {
        setUploadPreview(<UploadDisplay files={files} />);
      }, [files]);

      return (
        <>
          <div className='file-label-text'>
            {labelText}
          </div>
          <div className='file-box'>
            <label className={`file-label ${name}`}>
              <UploadPrompt />
              <input className={`file-input ${name}`} type='file' accept={accept} multiple={multiple}
                ref={(e) => {
                  ref(e);
                  inputRef.current = e;
                }}
                {...inputRegistration}
              ></input>
            </label>
            {uploadPreview}
          </div>
          <ErrorMessage errors={errors} name={name}
            render={({ message }) => {
              console.debug('Error:', name, errors[name]);
              return (
                <div className='error-box'>
                  <span className='error-message'>{message}</span>
                </div>
              );
            }}
          />
        </>
      );
    },
    Wrapped: ({ name, labelText, accept, multiple, required, validation, preamble, postamble }) => {
      return <InputWrapper
        input={<Input.File.Plain name={name} labelText={labelText} accept={accept} multiple={multiple} required={required} validation={validation} />}
        preamble={preamble} postamble={postamble} />;
    }
  }
}

/**
 * A wrapper for inputs containing a box, a preamble, and a postamble.
 * @param {ReactComponentElement} input the input to wrap
 * @param {Text} preamble text to include before the input
 * @param {Text} postamble text to include after the input
 */
function InputWrapper({ input, preamble, postamble }) {
  return (
    <div className='input-box'>
      {preamble && <div className='preamble'>{preamble}</div>}
      {input}
      {postamble && <div className='postamble'>{postamble}</div>}
    </div>
  );
}

/**
 * Validation functions for Inputs.
 */
export class Validator {
  static Text = class Text {
    static validEmail(email) {
      return EmailValidator.validate(email) || 'Invalid email';
    }

    static maxLength(maxlen) {
      return (str) => str.length <= maxlen || `${maxlen} character maximum`;
    }

    static minLength(minlen) {
      return (str) => str.length >= minlen || `${minlen} character minimum`;
    }
  };

  static Number = class Number {
    static isNumber(n) {
      return (!isNaN(n) && !isNaN(parseFloat(n))) || 'Value must be a number'
    }

    static positive(n) {
      return parseInt(n) >= 0 || 'Value must be greater than 0';
    }

    static maxValue(maxval) {
      return (val) => val <= maxval || `Value must be less than ${maxval}`;
    }

    static minValue(minval) {
      return (val) => val >= minval || `Value must be greater than ${minval}`;
    }
  };

  static File = class File {
    static #maxSizeB(B, errmsg) {
      return (files) => {
        const fileArr = Array.from(files);
        for (const file of fileArr) {
          if (file.size > B) {
            return errmsg;
          }
        }
        return true;
      }
    }

    static maxSizeKB(KB) {
      return Validator.File.#maxSizeB(KB * 1024, `Individual file size must be less than ${KB} KB`);
    }

    static maxSizeMB(MB) {
      return Validator.File.#maxSizeB(MB * 1048576, `Individual file size must be less than ${MB} MB`)
    }

    static isMimeType(errmsg, types) {
      return (files) => {
        const fileArr = Array.from(files);
        for (const file of fileArr) {
          if (!types.includes(file.type)) {
            return errmsg;
          }
        }
        return true;
      };
    }
  };

  static Checkbox = class Checkbox {
    static mustBeChecked(check) {
      return check || 'Checkbox must be checked';
    }
  };

  static CheckboxArray = class CheckboxArray {
    static oneMustBeChecked(checks) {
      return () => {
        return checks.some(check => check?.checked) || "At least one of the checkboxes must be checked";
      }
    }

    static xMustBeChecked(x) {
      return (checks) => {
        return () => {
          return checks.map(check => check?.checked).length >= x || `At least ${x} of the checkboxes must be checked`;
        }
      }
    }

    static allMustBeChecked(checks) {
      return () => {
        return checks.every(check => check?.checked) || "All checkboxes must be checked";
      }
    }
  }
};
