import Compressor from "compressorjs";
import convert from "heic-convert/browser";

const removeExtension = (fileName) =>
  fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

/**
 * Compress a non-HEIC file.
 * 
 * @param {File} file The file to compress as a File object
 * @param {Number} quality The quality of the compressed file
 * @default quality 0.8
 * @param {String} format The format of the compressed file
 * @default format "image/jpeg"
 * @returns The compressed file as a File object.
 */
const compress = async (file, quality = 0.8, mimeType = "image/jpeg") => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      mimeType,
      success: (compressedFile) => {
        if (compressedFile instanceof File) return resolve(compressedFile);
        else {
          let fileExtension;
          switch (mimeType) {
            case "image/jpeg":
            case "image/jpg":
              fileExtension = ".jpg";
              break;
            case "image/png":
              fileExtension = ".png";
              break;
            default:
              fileExtension = "";
          }

          const compressedFileFromBlob = new File([compressedFile], removeExtension(file.name) + fileExtension, {
            type: compressedFile.type,
          });
          return resolve(compressedFileFromBlob);
        }
      },
      error: reject,
    });
  });
}

function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      const arrayBuffer = event.target.result;
      resolve(arrayBuffer);
    };

    fileReader.onerror = function (event) {
      reject(event.target.error);
    };

    fileReader.readAsArrayBuffer(file);
  });
}

/**
 * Compress an HEIC file.
 * 
 * @param {File} file The file to compress as a File object
 * @param {Number} quality The quality of the compressed file
 * @default quality 0.8
 * @param {String} format The format of the compressed file
 * @default format "JPEG"
 * @returns The compressed file as a File object.
 */
const compressHeic = async (file, quality = 0.8, format = "JPEG") => {
  let compressedFileBuffer;
  try {
    const fileBuffer = await fileToArrayBuffer(file);
    compressedFileBuffer = await convert({
      buffer: new Uint8Array(fileBuffer),
      quality,
      format
    });
  } catch (error) {
    throw error;
  }

  let mimeType, fileExtension;
  switch (format) {
    case "JPEG":
      mimeType = "image/jpeg";
      fileExtension = ".jpg";
      break;
    case "PNG":
      mimeType = "image/png";
      fileExtension = ".png";
      break;
    default:
      mimeType = "";
      fileExtension = "";
  }

  const compressedBlob = new Blob([compressedFileBuffer], { type: mimeType });
  const compressedFile = new File([compressedBlob], removeExtension(file.name) + fileExtension, {
    type: mimeType
  });
  return compressedFile;
}

export {
  compress,
  compressHeic
};