import React, { forwardRef, useMemo } from 'react';
import SVGLibrary from '../scripts/svgLibrary';
import '../styles/hero.css';

const Hero = forwardRef(function Hero(props, ref) {

  const [Eng, Han] = useMemo(() => [SVGLibrary.Hero.English, SVGLibrary.Hero.Hangul], []);

  return (
    <div className='hero intro' ref={ref}>
      <div className='nameplate ka'>
        <div className='english'>
          <Eng.Ka />
        </div>
        <div className='hangul'>
          <Han.Ka />
        </div>
      </div>
      <div className='splitter'>&#x2B29;</div>
      <div className='nameplate ma'>
        <div className='english'>
          <Eng.Ma />
        </div>
        <div className='hangul'>
          <Han.Ma />
        </div>
      </div>
      <div className='splitter'>&#x2B29;</div>
      <div className='nameplate gi'>
        <div className='english'>
          <Eng.Gi />
        </div>
        <div className='hangul'>
          <Han.Gi />
        </div>
      </div>
    </div>
  );
});

export default Hero;